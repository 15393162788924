import * as React from 'react'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { Policy } from '../components/domains/privacy'

const PrivacyPage = () => {
	return (
		<Layout location={[{ name: 'プライバシーポリシー', path: '/privacy' }]}>
			<SEO title="プライバシーポリシー" description="" />
			<Policy />
		</Layout>
	)
}

export default PrivacyPage
