import React from 'react'

export const PrivacyOffer = () => {
	return (
		<div className="p-6 lg:p-10 mt-6 lg:mt-8 w-full border">
			<h3 className="text-sm font-semibold">開示対象個人情報の申し出先</h3>
			<p className="mt-4 text-xs leading-6">〒103-0004 東京都中央区東日本橋2丁目24−9 LIT 4F</p>
			<p className="text-xs leading-6">メール：privacy@lisatech.inc</p>
			<p className="text-xs leading-6">個人情報保護管理者 西村 龍紀</p>
		</div>
	)
}
